/*
 * Content:
 *
 * Sticky top below navbar height
 * Center all content
 */


// Sticky top below navbar height
// ============================================================================

.sticky-top-below-navbar {
  position: sticky;
  top: calc(#{$navbar-min-height} + #{$spacer});
  z-index: $zindex-sticky;

  @include media-breakpoint-up(md) {
    top: calc(#{$navbar-desktop-min-height} + #{$spacer});
  }
}


// Center all content
// ============================================================================

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}
