/*
 * Content:
 *
 * Form control
 * Form location box
 */


// Form control
// ============================================================================

.form-control {
  &:disabled,
  &:disabled::placeholder {
    color: $input-disabled-color;
  }

  &:read-only {
    cursor: default;
  }

  &.input-file {
    padding-right: spacer(7);
    cursor: pointer;
    background-image: escape-svg($form-input-file-icon);
    background-repeat: no-repeat;
    background-position: right spacer(4) center;
  }
}


// Form location box
// ============================================================================

.form-location-box {
  display: flex;
  flex-direction: column;
  gap: $spacer;

  button.btn {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: calc(#{sapcer(6) - 2px});

    .form-location-input-group {
      width: 42%;
    }

    button.btn {
      width: auto;
    }
  }
}
