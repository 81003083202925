/*
 * Content:
 *
 * Rc slider
 * Rc slider track
 * Rc tooltip arrow
 * Rc tooltip inner
 * Rc tooltip
 */


// Rc slider
// ============================================================================
@import "~rc-slider/assets/index.css";


// Rc slider track
// ============================================================================

.rc-slider-track {
  background-color: $primary;
}


// Rc tooltip arrow
// ============================================================================

.rc-tooltip-arrow {
  display: none;
}


// Rc tooltip inner
// ============================================================================

.rc-tooltip-inner {
  // stylelint-disable declaration-no-important
  color: $body-color !important;
}


// Rc tooltip
// ============================================================================

.rc-tooltip {

  // stylelint-disable selector-class-pattern
  &.rc-tooltip-placement-bottomRight,
  &.rc-tooltip-placement-bottomLeft {
    z-index: $zindex-dropdown;
  }
}
