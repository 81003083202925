/*
 * Content:
 *
 * Modules
 * Paths
 * Color system
 * Spacers
 * Options
 * Body
 * Utilities maps
 * Components
 * Typography
 * Buttons
 * Navbar
 * Footer
 * Forms
 * Dropdown
 * Close
 * Bootstrap variables
 * Choices variables
 * Card
 * Accordion
 */


// Modules
// ============================================================================

@use "sass:map";


// Paths
// ============================================================================

$images-path: "../img/";
$fonts-path: "../fonts/";


// Color system
// ============================================================================

// Grays
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

// Grays custom
$gray-default: #bdbdbd;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

$grays-rgb: map-loop($grays, to-rgb, "$value");

// Colors
$aqua: #32cfcc;
$aqua-dark: #229290;
$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$gray: $gray-default;

$colors: (
  "aqua": $aqua,
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-default,
  "gray-dark": $gray-800
);

// Themes
$primary: $aqua;
$primary-dark: $aqua-dark;
$secondary: $gray-default;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$theme-colors: (
  "primary": $primary,
  "primary-dark": $primary-dark,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

// Options
// ============================================================================

$enable-negative-margins: true;


// Spacers
// ============================================================================

$spacer: 1rem;
$spacers: (
  0: 0,
  // 4px
  1: $spacer * .25,
  // 8px
  2: $spacer * .5,
  // 16px
  3: $spacer,
  // 20px
  4: $spacer * 1.25,
  // 24px
  5: $spacer * 1.5,
  // 32px
  6: $spacer * 2,
  // 48px
  7: $spacer * 3,
  // 64px
  8: $spacer * 4,
  // 96px
  9: $spacer * 6,
);


// Body
// ============================================================================

$body-color: $gray-900;
$body-bg: #f8f9fa;


// Utilities maps
// ============================================================================

$utilities-colors: map.merge(
  map.merge(
    $theme-colors-rgb,
    map-add-key-prefix($grays-rgb, gray-)
  ),
  ("black": to-rgb($black),
  "white": to-rgb($white),
  "body": to-rgb($body-bg))
);


// Components
// ============================================================================

$shadow-color: rgb(0 0 0 / 22%);


// Typography
// ============================================================================

$font-family-sans-serif: "Montserrat", sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base-in-px: 16px;

// font-size bootstrap reference for use font-size bootstrap mixin (responsive font size)
// see bootstrap/scss/_type.scss
// see bootstrap/scss/vendor/_rfs.scss
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;

$line-height-base: 1.5;

// Headings
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-line-height: 1.2;

// Displays
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display-line-height: $headings-line-height;

// Lead paragraph
$lead-font-size: $font-size-base * 1.25;

// Small text
$small-font-size: 80%;


// Buttons
// ============================================================================

$btn-border-radius: 30px;
$btn-disabled-opacity: 1;

/* Approximate percentage values to generate button variants */
$btn-active-bg-shade-amount: 10%;
$btn-hover-bg-shade-amount: 30%;
$btn-hover-border-shade-amount: 30%;
$btn-disabled-bg-tint-amount: 80%;
$btn-disabled-border-tint-amount: 80%;

$btn-outline-hover-border-shade-amount: 30%;


// Navbar
// ============================================================================

$navbar-min-height: 80px;
$navbar-desktop-min-height: 64px;
$navbar-padding-y: $spacer;
$navbar-desktop-padding-y: spacer(2);
$navbar-nav-item-link-padding-x: 14.4px;


//Navbar toggler
$navbar-toggler-focus-width: 0;


// Navbar toggler icon
$navbar-toggler-icon-color: $dark;
$navbar-toggler-icon-bg: url("data:image/svg+xml,<svg width='18' height='12' viewBox='0 0 18 12' fill='#{$navbar-toggler-icon-color}' xmlns='http://www.w3.org/2000/svg'><path d='M0 12H18V10H0V12Z  M0 7H18V5H0V7ZM0 0V2H18V0H0Z' /></svg>");

//Nav link
$nav-link-color: $body-color;
$nav-link-border-bottom: 1px solid $gray-300;
$nav-link-padding-x: $spacer;


//Nav link icon
$nav-link-icon-padding: spacer(2);
$nav-link-icon-border: 1px solid $body-color;


// Footer
// ============================================================================

$footer-min-height: 56px;


// Forms
// ============================================================================

$input-bg: $white;
$input-color: $body-color;
$input-disabled-color: $input-color;
$input-focus-border-color: $primary;

$input-padding-y: spacer(2);
$input-padding-x: spacer(3);
$input-border-radius: 10px;
$input-focus-box-shadow: 0 0 0 2px #d7f6f580;
$input-disabled-color: $gray-600;

// Input File
$form-input-file-icon: url("data:image/svg+xml,<svg width='14' height='17' viewBox='0 0 14 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4 13H10V7H14L7 0L0 7H4V13ZM7 2.83L9.17 5H8V11H6V5H4.83L7 2.83ZM0 15H14V17H0V15Z' fill='#212529'/></svg>");

// Select
$form-select-disabled-color: $input-disabled-color; // This value can't be `null` for overriding choices styles


// label
$form-label-margin-bottom: spacer(1);


// Dropdown
// ============================================================================

$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: shade-color($primary, $btn-hover-bg-shade-amount);


// Close
// ============================================================================

$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.26396 4.00002L7.15923 2.10476C7.3918 1.87218 7.3918 1.4951 7.15923 1.26233L6.73801 0.841119C6.50544 0.608543 6.12836 0.608543 5.89559 0.841119L4.00033 2.73638L2.10506 0.841119C1.87248 0.608543 1.4954 0.608543 1.26264 0.841119L0.841424 1.26233C0.608848 1.49491 0.608848 1.87199 0.841424 2.10476L2.73669 4.00002L0.841424 5.89529C0.608848 6.12786 0.608848 6.50494 0.841424 6.73771L1.26264 7.15892C1.49521 7.3915 1.87248 7.3915 2.10506 7.15892L4.00033 5.26366L5.89559 7.15892C6.12817 7.3915 6.50544 7.3915 6.73801 7.15892L7.15923 6.73771C7.3918 6.50513 7.3918 6.12805 7.15923 5.89529L5.26396 4.00002Z' fill='#{$btn-close-color}'/></svg>");


// Bootstrap variables
// ============================================================================

@import "~bootstrap/scss/variables";

// Buttons
$btn-padding-y: spacer(2);
$btn-padding-x: spacer(3);


// Choices variables
// ============================================================================

$choices-guttering: 0;
$choices-border-radius: $form-select-border-radius;
$choices-bg-color: $form-select-bg;
$choices-bg-color-disabled: $form-select-disabled-bg;
$choices-keyline-color: $form-select-border-color;

// Dropdown
$choices-bg-color-dropdown: $gray-100;
$choices-dropdown-border-width: 1px;
$choices-dropdown-border-color: $choices-keyline-color;

$choices-dropdown-item-paddiny-y: $form-select-padding-y;
$choices-dropdown-item-paddiny-x: $form-select-padding-x;
$choices-dropdown-item-font-size: $font-size-base;
$choices-dropdown-item-line-height: $line-height-base;
$choices-dropdown-item-color: $dropdown-link-color;

$choices-dropdown-item-active-color: $dropdown-link-active-color;
$choices-dropdown-item-active-bg: $dropdown-link-active-bg;

$choices-dropdown-item-hover-color: $dropdown-link-hover-color;
$choices-dropdown-item-hover-bg: $dropdown-link-hover-bg;

$choices-dropdown-item-disabled-color: $dropdown-link-disabled-color;

$choices-dropdown-group-item-padding-l: $choices-dropdown-item-paddiny-x * 2;

// Multiple item
$choices-border-radius-item: $badge-border-radius;
$choices-multiple-item-padding-y: 0;
$choices-multiple-item-padding-x: spacer(1);
$choices-multiple-item-bg: $gray-100;
$choices-multiple-item-font-size: $font-size-base;
$choices-multiple-item-line-height: $line-height-base;
$choices-multiple-item-color: $secondary;
$choices-multiple-item-border-width: $border-width;
$choices-multiple-item-border-color: $choices-multiple-item-color;
$choices-multiple-item-spacer: spacer(2);

$choices-multiple-item-disabled-bg: $form-select-disabled-bg;
$choices-multiple-item-disabled-color: $choices-multiple-item-color;
$choices-multiple-item-disabled-border-color: $choices-multiple-item-border-color;

// Remove button
$choices-button-dimension: .5rem;
$choices-button-offset: spacer(1);
$choices-button-color: $choices-multiple-item-color;
$choices-button-disabled-color: $choices-multiple-item-disabled-color;
$choices-button-focus-shadow: $btn-close-focus-shadow;

$choices-icon-cross-svg: str-replace($btn-close-bg, #{$btn-close-color}, #{$choices-button-color});
$choices-icon-cross: escape-svg($choices-icon-cross-svg);

// Heading
$choices-heading-padding-y: $choices-dropdown-item-paddiny-y;
$choices-heading-padding-x: $choices-dropdown-item-paddiny-x;
$choices-heading-font-weight: $font-weight-base;
$choices-heading-font-size: $font-size-base;
$choices-heading-line-height: $line-height-base;
$choices-heading-color: $gray-700;
$choices-heading-border-width: $border-width;
$choices-heading-border-color: $border-color;

// Swiper variables
// ============================================================================

$swiper-padding: map.get($spacers, 3);
$swiper-slide-margin-right: spacer(5);
$swiper-slide-desktop-margin-right: spacer(3);
$swiper-slide-max-width: 17.5rem; // 280px

// Swipper bullet
$swiper-bullet-size: .75rem; // 12px
$swiper-bullet-bg-color: $gray-default;
$swiper-bullet-bg-active-color: $primary;

// Swiper navigation buttons
$swiper-navigation-btn-size: 2.5rem; // 40px
$swiper-navigation-btn-color: $white;
$swiper-navigation-btn-position-translate: $spacer; // 16px

// Swiper image slider
$swiper-image-slider-padding-bottom: spacer(6);

// Swiper carousel
$swiper-carousel-spacer-y: spacer(6);


// Card
// ============================================================================

$card-border-width: 0;
$card-border-radius: 24px;
$card-spacer-y: spacer(5);
$card-box-shadow: 0 0 10px rgb(0 0 0 / 15%);

// Card search box
$card-search-box-max-width: 57.5rem; // 920px
$card-search-box-padding: $spacer;
$card-search-box-desktop-border-radius: 24px;

// Card info
$card-info-border: 1px solid $gray-300;
$card-info-padding-y: $spacer;
$card-info-padding-x: spacer(2);
$card-info-desktop-padding: spacer(6);
$card-info-icon-size: 3.75rem; // 60px
$card-info-desktop-icon-size: 8.375rem; // 134px
$card-info-text-padding-right: spacer(4);
$card-info-text-margin-left: spacer(2);
$card-info-text-desktop-margin-top: spacer(2);
$card-info-text-desktop-padding-x: spacer(2);

// Card filters
$card-filter-padding: $spacer(2);

// Card featured

$card-featured-label: escape-svg(url("data:image/svg+xml,<svg width='53' height='66' viewBox='0 0 53 66' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M26.5 47.6667L53 66V7.33333V0H45.4286H7.57143H0V7.33333V66L26.5 47.6667Z' fill='url(#paint0_linear_1124_12721)'/><defs><linearGradient id='paint0_linear_1124_12721' x1='8.07544' y1='3.4855e-05' x2='53.4283' y2='1.25133' gradientUnits='userSpaceOnUse'><stop stop-color='#F8A170'/><stop offset='1' stop-color='#FFCD61'/></linearGradient></defs></svg>"));
$card-featured-label-star: escape-svg(url("data:image/svg+xml,<svg width='30' height='29' viewBox='0 0 30 29' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.9344 22.8118L23.8836 28.2105L21.5087 18.0355L29.4153 11.1895L19.0036 10.3066L14.9344 0.71051L10.8653 10.3066L0.453552 11.1895L8.36011 18.0355L5.98525 28.2105L14.9344 22.8118Z' fill='white'/></svg>"));


// Accordion
// ============================================================================

$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;
$accordion-shadow: 0 0 10px rgb(0 0 0 / 15%);


// Accordion item
$accordion-item-margin-bottom: calc(map.get($spacers, 3) - 2px);
$accordion-item-border-radius: 24px;


// Accordion button

$accordion-button-icon: url("data:image/svg+xml,<svg width='12' height='8' viewBox='0 0 12 8' fill='#{$accordion-icon-color}' xmlns='http://www.w3.org/2000/svg'><path d='M1.41 0.589996L6 5.17L10.59 0.589996L12 2L6 8L0 2L1.41 0.589996Z' /></svg>");
$accordion-button-active-color: $primary-dark;
$accordion-button-active-icon: url("data:image/svg+xml,<svg width='12' height='8' viewBox='0 0 12 8' fill='#{$accordion-button-active-color}' xmlns='http://www.w3.org/2000/svg'><path d='M1.41 0.589996L6 5.17L10.59 0.589996L12 2L6 8L0 2L1.41 0.589996Z' /></svg>");
$accordion-button-focus-box-shadow: none;


// Accordion item
$accordion-icon-active-color: $primary-dark;
$accordion-icon-width: .75rem; // 12px

// Accordion body
$accordion-body-border-top: 1px solid $gray-300;
