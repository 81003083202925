/*
 * Content:
 *
 * Krpano container
 * Krpano full size
 */


// Krpano container
// ============================================================================

.krpano-box {
  position: relative;
  height: 20.625rem; //330px
  overflow: hidden;
  border-radius: 24px;

  @include media-breakpoint-up(md) {
    height: 29.94rem; //479px
  }

  .krpano-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999; // less than the minimum bootstrap z-index to avoid overlaping other components
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .pano {
    width: 100%;
    height: 100%;
  }
}


// Krpano full size
// ============================================================================

.krpano-full {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100vw;
  height: 100vh;

  .btn {
    position: absolute;
    top: $spacer;
    left: $spacer;
  }

  .krpano-loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: $font-weight-bold;
    color: $white;
    background-color: $dark;
  }

  .pano {
    width: 100%;
    height: 100%;
  }
}
