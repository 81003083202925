/*
 * Content:
 *
 * Navbar
 * Navbar brand
 * Navbar toggler
 * Navbar toggler icon
 * Nav link
 * Nav link icon
 * Nav collapse
 */


// Navbar
// ============================================================================

.navbar {
  min-height: $navbar-min-height;
  border-bottom: 1px solid #e9ecef;

  @include media-breakpoint-up(md) {
    min-height: $navbar-desktop-min-height;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $navbar-desktop-padding-y;
    padding-bottom: $navbar-desktop-padding-y;
  }
}


// Navbar brand
// ============================================================================

.navbar-brand {
  padding: 0;
}


// Navbar toggler
// ============================================================================

.navbar-toggler {
  padding: $navbar-toggler-padding-y px($navbar-toggler-padding-x);
  font-size: px($navbar-toggler-font-size);
  border: none;
}


// Navbar toggler icon
// ============================================================================

.navbar-toggler-icon {
  width: 1.125rem; // 18px
  height: .75rem; //12px
  background-image: escape-svg($navbar-toggler-icon-bg);
}


// Nav link
// ============================================================================

.nav-link {
  text-decoration: underline;
  border-bottom: $nav-link-border-bottom;

  @include media-breakpoint-up(md) {
    border-bottom: none;
  }

  &.active {
    color: $primary;
  }
}


// Nav link icon
// ============================================================================

.nav-link-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6rem;
  padding: $nav-link-icon-padding;
  font-size: 1.25rem; // 20px
  text-decoration: none;
  border: $nav-link-icon-border;
  border-radius: 50%;
}


// Nav collapse
// ============================================================================

.navbar-collapse {
  flex-grow: unset;
}
