/*
 * Content:
 *
 * Modules
 * Bootstrap mixins
 * Backgrounds
 * Generate buttons mixins
 */


// Modules
// ============================================================================

@use "sass:map";
@use "sass:color";


// Bootstrap mixins
// ============================================================================

@import "~bootstrap/scss/mixins";


// Backgrounds
// ============================================================================

@mixin split-background($color-a, $color-b) {
  background: linear-gradient(to right, $color-a 0%, $color-a 50%, $color-b 50%, $color-b 100%);
}


// Generate buttons mixins
// ============================================================================

@mixin generate-button-variant(
  $background,
  $border,
  $color: $body-color,
  $hover-background: shade-color($background, $btn-hover-bg-shade-amount),
  $hover-border: shade-color($border, $btn-hover-border-shade-amount),
  $hover-color: $body-color,
  $active-background:shade-color($background, $btn-active-bg-shade-amount),
  $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
  $active-color: $body-color,
  $disabled-background: tint-color($background, $btn-disabled-bg-tint-amount),
  $disabled-border: tint-color($background, $btn-disabled-border-tint-amount),
  $disabled-color: $body-color
) {
  color: $color;

  @include gradient-bg($background);
  border-color: $border;

  @include box-shadow($btn-box-shadow);

  &:hover {
    color: $hover-color;

    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  .btn-check:focus + &,
  &:focus {
    color: $hover-color;

    @include gradient-bg($hover-background);
    border-color: $hover-border;

    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 $btn-focus-width rgba(color.mix($color, $border, 15%), .5);
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(color.mix($color, $border, 15%), .5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $disabled-color;
    background-color: $disabled-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $disabled-border;
  }
}

@mixin generate-button-outline-variant(
  $btn-color,
  $text-color: $body-color,
  $hover-border: shade-color($btn-color, $btn-outline-hover-border-shade-amount),
  $hover-bg-color: $white,
  $active-background: $white,
  $active-border: $btn-color,
  $active-color: $text-color
) {
  color: $text-color;
  border-color: $btn-color;

  &:hover {
    color: $text-color;
    background-color: $hover-bg-color;
    border-color: $hover-border;
  }

  .btn-check:focus + &,
  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($btn-color, .5);
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba($btn-color, .5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $text-color;
    background-color: transparent;
  }
}
