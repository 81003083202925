/*
 * Content:
 *
 * Map container
 */


// Map container
// ============================================================================

.map-container {
  width: 100%;
  height: 247px;
  border-radius: 24px;

  @include media-breakpoint-up(md) {
    height: 410px;
  }
}
