/*
 * Content:
 *
 * Card base
 * Card title
 * Card search box
 * Card info
 * Card filters
 * Card featured
 * Card project
 */


// Card base
// ============================================================================

.card {
  box-shadow: $card-box-shadow;
}


// Card title
// ============================================================================

.card-title {
  font-weight: 600;
}


// Card search box
// ============================================================================

.card-search-box {
  position: relative;
  z-index: 1; // Just enough to place the searchbox over the carousel in Home view
  max-width: $card-search-box-max-width;
  margin-right: -$container-padding-x;
  margin-left: -$container-padding-x;
  border-radius: 0;

  .card-body {
    padding: $card-search-box-padding;
  }

  @include media-breakpoint-up(md) {
    margin-right: auto;
    margin-left: auto;
    border-radius: $card-search-box-desktop-border-radius;
  }
}


// Card info
// ============================================================================

.card-info {
  border: $card-info-border;
  box-shadow: none;

  .card-body {
    display: flex;
    align-items: center;
    padding: $card-info-padding-y $card-info-padding-x;
  }

  .card-info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $card-info-icon-size;
    height: $card-info-icon-size;

    img {
      width: auto;
      height: 70%;
    }
  }

  .card-info-text {
    padding-right: $card-info-text-padding-right;
    margin-bottom: 0;
    margin-left: $card-info-text-margin-left;
  }

  @include media-breakpoint-up(md) {
    .card-body {
      flex-direction: column;
      padding: $card-info-desktop-padding;
    }

    .card-info-icon {
      width: $card-info-desktop-icon-size;
      height: $card-info-desktop-icon-size;
    }

    .card-info-text {
      padding: 0 $card-info-text-desktop-padding-x;
      margin-top: $card-info-text-desktop-margin-top;
      margin-left: 0;
      text-align: center;
    }
  }
}

// Card filters
// ============================================================================

.card-filters {
  border-radius: 14px;

  .card-body {
    padding: $card-filter-padding;
  }
}

// Card featured
// ============================================================================

.card-featured:first-child {
  .card-image {
    position: relative;

    .featured-label {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: $card-featured-label-star, $card-featured-label;
      background-repeat: no-repeat;
      background-position: top .62rem right 2rem, top right 1.25rem;
      background-size: 1.87rem, 3.43rem;
    }
  }
}


// Card project
// ============================================================================

.card-project {
  max-width: 21rem;

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 19.5rem; // 312px

    dl {
      margin-bottom: spacer(0);

      dd {
        height: 3rem;
        margin-left: spacer(1);
        overflow: hidden;
      }
    }
  }

  .card-image {
    height: 8.9rem; // 143px

    .card-img-top {
      height: 100%;
      object-fit: cover;
    }
  }
}
