/*
 * Content:
 *
 * Button variants
 * Button icon
 */


// Generate button variants
// ============================================================================

@each $color,
  $value in $theme-colors {
  .btn-#{$color} {
    @include generate-button-variant($value, $value);
  }
}

@each $color,
  $value in $theme-colors {
  .btn-outline-#{$color} {
    @include generate-button-outline-variant($value);
  }
}


// Button icon
// ============================================================================

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: none;
  border-radius: 50%;

  &-lg {
    width: 40px;
    height: 40px;
  }

  .icon {
    font-size: 16px;
  }
}
