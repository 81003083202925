/*
 * Content:
 *
 * Modules
 * Alert color variants
 * Highlight alert
 */


// Modules
// ============================================================================

@use "sass:color";


// Alert color variants
// ============================================================================

@each $state,
  $value in $theme-colors {
  $alert-background: $white;
  $alert-border: $value;
  $alert-color: $body-color;

  .alert-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color);

    .icon {
      color: $value;
    }

    .btn-close {
      $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$value}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
      background: transparent escape-svg($bg-image) center / .75rem auto no-repeat;
    }
  }
}


// Highlight alert
// ============================================================================

.highlight {
  @each $color,
    $value in $theme-colors {
    &.alert-#{$color} {
      background: color.adjust($value, $lightness: -10%);
    }
  }
}
