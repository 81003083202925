/*
 * Content:
 *
 * Accordion item
 * Accordion button
 * Accordion body
 */


// Accordion item
// ============================================================================

.accordion-item {
  margin-bottom: $accordion-item-margin-bottom;
  overflow: hidden;
  border-radius: $accordion-item-border-radius;
  box-shadow: $accordion-shadow;

  &:first-of-type,
  &:last-of-type {
    border-radius: $accordion-item-border-radius;
  }

  &:not(:first-of-type) {
    border-top: $accordion-border-width solid $accordion-border-color;
  }
}


// Accordion button
// ============================================================================

.accordion-button {
  font-weight: 600;
  line-height: $line-height-base;

  &:not(.collapsed) {
    box-shadow: none;
  }

  &::after {
    background-position: 100% 100%;
  }
}


// Accordion body
// ============================================================================

.accordion-body {
  border-top: $accordion-body-border-top;
}
