/*
 * Content:
 *
 * Input group
 */


// Input group
// ============================================================================

.input-group {
  .form-control {
    &:not(:focus) {
      &:first-child {
        border-right-color: transparent;
      }

      &:last-child {
        border-left-color: transparent;
      }
    }
  }

  .btn {
    height: unset;
    border: $input-border-width solid $input-border-color;

    &:first-child {
      border-right: none;
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }

    &:last-child {
      border-left: none;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
  }
}
