/*
 * Content:
 *
 * Modules
 * Swiper
 * Base
 * Swiper slide
 * Swipper pagination
 * Swiper pagination bullets
 * Swiper navigation buttons
 * Swiper image slider
 * Swiper carousel
 */


// Modules
// ============================================================================

@use "sass:map";


// Swiper
// ============================================================================

@import "swiper/css";
@import "swiper/css/free-mode";
@import "swiper/css/pagination";
@import "swiper/css/navigation";


// Base
// ============================================================================

.swiper {
  padding: $swiper-padding;
}


// Swiper slide
// ============================================================================

.swiper-slide {
  max-width: $swiper-slide-max-width;
  margin-right: $swiper-slide-margin-right;
}

@include media-breakpoint-up(md) {
  .swiper-slide {
    margin-right: $swiper-slide-desktop-margin-right;
  }
}


// Swiper pagination bullets
// ============================================================================

.swiper-pagination.swiper-pagination-bullets {
  bottom: 0;
}

.swiper-pagination-bullet {
  width: $swiper-bullet-size;
  height: $swiper-bullet-size;
  background-color: $swiper-bullet-bg-color;
  opacity: 1;

  &-active {
    background-color: $swiper-bullet-bg-active-color;
  }
}


// Swiper navigation buttons
// ============================================================================

.swiper-button-next,
.swiper-button-prev {
  width: $swiper-navigation-btn-size;
  height: $swiper-navigation-btn-size;
  padding: spacer(2);
  color: $swiper-navigation-btn-color;
  border: 1px solid $swiper-navigation-btn-color;
  border-radius: 50%;

  &::after {
    font-family: "Material Symbols Outlined", sans-serif;
    font-size: 1.5rem;
  }
}

.swiper-button-next {
  right: $swiper-navigation-btn-position-translate;

  &::after {
    content: "keyboard_arrow_right";
  }
}

.swiper-button-prev {
  left: $swiper-navigation-btn-position-translate;

  &::after {
    content: "keyboard_arrow_left";
  }
}


// Swiper image slider
// ============================================================================

.swiper-image-slider {
  padding: 0;
  padding-bottom: $swiper-image-slider-padding-bottom;

  .image-slide {
    position: relative;
    width: 100%;
    aspect-ratio: 2 / 1;
    overflow: hidden;
    border-radius: 1.5rem;

    &.image-slide-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary;
      background-color: $dark;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .swiper-slide {
    margin: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }


  @include media-breakpoint-up(md) {
    padding-bottom: 0;

    .swiper-pagination.swiper-pagination-bullets {
      bottom: $swiper-image-slider-padding-bottom;
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
    }
  }
}


// Swiper carousel
// ============================================================================

.swiper-carousel {
  aspect-ratio: 4 / 3;
  padding: 0;
  margin-right: -$container-padding-x;
  margin-left: -$container-padding-x;

  .swiper-slide {
    margin-right: 0;
  }

  .swiper-carousel-image {
    display: flex;
    justify-content: center;
    height: 100%;
    background-size: cover;

    .swiper-carousel-text {
      margin-top: $swiper-carousel-spacer-y;
      font-weight: 600;
      color: #fff;
    }
  }


  .swiper-pagination {
    &.swiper-pagination-bullets {
      bottom: $swiper-carousel-spacer-y;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  @include media-breakpoint-up(md) {
    aspect-ratio: 2 / 1;

    .swiper-carousel-image {
      align-items: center;
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
    }
  }
}
